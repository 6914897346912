import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

import Layout from './Layout.js';

import Homepage from './Pages/Home/index';
import Uses from './Pages/Uses/index';
import SA from './Pages/Case-studies/SerialAffinity/index';
import NotFound from './NotFound';
import './styles.scss';

const routing = (
  <Router>
    <Layout>
      <Switch>
        <Route exact path="/" component={Homepage} />
        <Route path="/uses" component={Uses} />
        <Route path="/serial-affinity" component={SA} />
        <Route path="/404" component={NotFound} />
        <Redirect from="*" to="/404" />
      </Switch>
    </Layout>
  </Router>
);
ReactDOM.render(routing, document.getElementById('root'));

serviceWorker.register();
