import React from 'react';
import Mugshot from '../../assets/imgs/mark_austin-outrun-med.png';
// import Anaglyph from '../library/Anaglyph/anaglyph.js';
import './style.scss';

export const PageHeader = ({ className, aos }) => {
  return (
    <section className={`page-header__container ${className}`} data-aos={aos} id="about">
      <div className="page-header__col-1-2">
        <img className="page-header__mugshot" src={Mugshot} alt="" />
      </div>
      <div className="page-header__col-2-2">
        <h1 className="page-header__title">
          <span>Hi! I’m</span>
          <br />
          Mark Austin
        </h1>
        <p className="page-header__about">An Innovative Software Developer specialising in immersive, responsive and accessible digital storytelling experiences.</p>
        <p className="page-header__location">- Currently residing in Glasgow, Scotland.</p>
      </div>
    </section>
  );
};

export default PageHeader;
