import { useState, useEffect } from 'react';
import { useWindowSize } from './useWindowSize.js';

// same as `src/scss/variables/_breakpoints.scss`
const breakpoints = {
  'mobile-s': 320,
  mobile: 375,
  'mobile-l': 425,
  'tablet-s': 580,
  tablet: 768,
  'tablet-l': 1024,
  desktop: 1200,
};

/**
 *
 * @return {object} The breakpoints object, with true/false values for whether the breakpoint is active
 *
 * @example
 *     const breakpoints = useBreakpoints(); // breakpoints = { 'mobile-s': true, 'mobile': false }
 *     isMobile = breakpoints['mobile']; // false
 *     isMobileS = breakpoints['mobile-s']; // true
 */

export const useBreakpoints = () => {
  const { width } = useWindowSize();

  const [activeBreakpoints, setActiveBreakpoints] = useState({});

  useEffect(() => {
    const newActiveBreakpoints = {};
    Object.keys(breakpoints).forEach((key) => {
      const b = breakpoints[key];
      newActiveBreakpoints[key] = width >= b;
    });
    setActiveBreakpoints(newActiveBreakpoints);
  }, [width]);

  return activeBreakpoints;
};

export default useBreakpoints;
