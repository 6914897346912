import { useEffect, useCallback, useRef } from 'react';
import { useSafeState } from './useSafeState.js';

const useState = useSafeState;
const isClient = typeof window === 'object';

export const useWindowSize = () => {
    const debouncedWindowSizeChange = useRef(null);

    const getSize = useCallback(() => {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined
        };
    }, []);

    const handleResize = useCallback(() => {
        if (debouncedWindowSizeChange.current) clearTimeout(debouncedWindowSizeChange.current);
        debouncedWindowSizeChange.current = setTimeout(() => {
            setWindowSize(getSize());
        }, 250);
    }, [getSize]);
    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        if (!isClient) {
            return false;
        }

        window.addEventListener('resize', handleResize);
        return () => {
            return window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    return windowSize;
};

export default useWindowSize;
