import { useRef, useState, useEffect, useCallback } from 'react';

export const useSafeState = (initialState) => {
    const [state, setState] = useState(initialState);

    const mountedRef = useRef(false);
    useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        };
    }, []);
    const safeSetState = useCallback((...args) => mountedRef.current && setState(...args), []);

    return [state, safeSetState];
};

export default useSafeState;
