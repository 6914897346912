import React, { useEffect } from 'react';
import Skip2Content from '../../components/Skip2Content';
import Hero from '../../components/Hero';
import PageHeader from '../../components/PageHeader';
import Work from '../../components/Work';
import TechStack from '../../components/TechStack';
import Interests from '../../components/Interests';

import AOS from 'aos';
import 'aos/dist/aos.css';

import './style.scss';

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      offset: 300,
    });
  }, []);

  return (
    <div id="homepage">
      <Skip2Content />
      <Hero />
      <main id="main">
        <PageHeader aos="fade-up" />
        <Work aos="fade-up" />
        <TechStack aos="fade-up" />
        <Interests aos="fade-up" />
      </main>
    </div>
  );
};

export default App;
