import React from 'react';
import SvgIcon from '../library/SvgIcon/SvgIcon.js';
import work from '../../api/experience.json';

import './style.scss';

const Work = ({ className, aos }) => {
  return (
    <section className={`work__container ${className}`} data-aos={aos} id="work">
      <h2>Worked With</h2>
      <ul className="work__list">
        {work.experience.map((data) => {
          return (
            <li className="work__item" key={data.job}>
              <SvgIcon className="work__icon" icon={data.job} />
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default Work;
