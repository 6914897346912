import React, { useState, useEffect } from 'react';
import { ReactComponent as Logo } from '../../assets/imgs/markaustin-dev__logo.svg';
import { HashLink as Link } from 'react-router-hash-link';
import SocialLinks from '../SocialLinks/index.js';

import './style.scss';
import Modal from 'react-modal';

import LegalDoc from '../../assets/docs/legal.md';

const ReactMarkdown = require('react-markdown');

const Footer = () => {
  const [isModalOpen, setModal] = useState(false);
  const [markdown, setMarkdown] = useState();

  const handleOpenModal = () => {
    setModal(true);
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  useEffect(() => {
    Modal.setAppElement('body');
    fetch(LegalDoc)
      .then((res) => res.text())
      .then((text) => setMarkdown(text));
  }, []);

  return (
    <>
      <footer>
        <div className="footer__copyright ">
          <Logo
            className="footer__logo"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          />
          ©{new Date().getFullYear().toString()}
        </div>
        <nav className="footer__nav  ">
          <h4 className="footer__title">Sections</h4>
          <ul className="footer-nav__list">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li className="desktop-hide">
              <Link to="/#about">About</Link>
            </li>
            <li className="desktop-hide">
              <Link to="/#work">Work</Link>
            </li>
            <li className="desktop-hide">
              <Link to="/#tech-stack">Tech Stack</Link>
            </li>
            <li className="desktop-hide">
              <Link to="/#interests">Interests</Link>
            </li>
            <li>
              <Link to="/uses">Uses</Link>
            </li>
            <li>
              <Link to="/serial-affinity">Cases</Link>
            </li>
            <li>
              <span className="fake-link" onClick={handleOpenModal}>
                Legal
              </span>
            </li>
          </ul>
        </nav>
        <div className="footer__contact " id="contact">
          <h4 className="footer__title">Contact</h4>
          <address>
            <p>
              based in Glasgow,
              <br />
              Scotland
            </p>

            <a href="mailto:me@markaustin.dev" subject="markaustin.dev enquiry! - *add your message here*" rel="noopener noreferrer" target="_blank">
              Write to Mark
            </a>
          </address>
        </div>
        <div className="footer__networking ">
          <h4 className="footer__title">Follow</h4>
          <SocialLinks />
        </div>
      </footer>

      <Modal isOpen={isModalOpen} contentLabel="Legal Information">
        <div className="modal">
          <div className="title__wrap">
            <div className="title">Legal</div>
          </div>

          <div className="content__container">
            <div className="content">
              <ReactMarkdown source={markdown} />
            </div>
          </div>
          <span className="content-stylistic__bottom"></span>
        </div>

        <div className="btn__container" onClick={handleCloseModal}>
          <div className="btn">Close</div>
          <div className="btn-stylistic__bottom"></div>
        </div>
      </Modal>
    </>
  );
};

export default Footer;
