import React, { useState, useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import AOS from 'aos';

// import Masthead_Sky from './../../../assets/case-studies/SA/masthead--sky.png';
// import Masthead_Overlay from './../../../assets/case-studies/SA/masthead--overlay.png';
import BrandLogo from './../../../assets/case-studies/SA/brand-logo--black.png';
import Moodboard from './../../../assets/case-studies/SA/moodboard.jpg';
import BrandSymbolismLeft from './../../../assets/case-studies/SA/brand-symbolism_left.png';
import BrandSymbolismRight from './../../../assets/case-studies/SA/brand-symbolism_right.png';
import TypefaceResearch from './../../../assets/case-studies/SA/typeface-research.png';
import LogoDesign from './../../../assets/case-studies/SA/logo-design.jpg';
import LogoDesign2 from './../../../assets/case-studies/SA/logo-design-2.png';
import LogoDesign3 from './../../../assets/case-studies/SA/logo-design-3.png';
import ColourSwatch from './../../../assets/case-studies/SA/colour-swatch.png';
import Grid from './../../../assets/case-studies/SA/grid.png';
import Mockup_Light from './../../../assets/case-studies/SA/mockup--light.png';
import Mockup_Dark from './../../../assets/case-studies/SA/mockup--dark.png';
import Typeface_Primary from './../../../assets/case-studies/SA/typeface-selection--primary.png';
import Typeface_Secondary from './../../../assets/case-studies/SA/typeface-selection--secondary.png';
import MobileMockup from './../../../assets/case-studies/SA/floating-phone.gif';
import ProgressBar from './../../../components/library/ProgressBar/index.js';

import './style.scss';
import '../case-study.scss';

const SerialAffinity = () => {
  const [isDefault, setTheme] = useState(true);
  const toggleTheme = () => setTheme(!isDefault);

  useEffect(() => {
    AOS.init({
      duration: 2000,
      offset: 300,
    });
  }, []);

  return (
    <div className="case-study CS--serialAffinity">
      <ProgressBar />

      <div className="contents-nav">
        <ol className="contents__list">
          <li>
            <Link to="#intro">Intro</Link>
          </li>
          <li>
            <Link to="#moodboard">Moodboard</Link>
          </li>
          <li>
            <Link to="#typeface">Typeface</Link>
          </li>
          <li>
            <Link to="#icon-design">Icon Design</Link>
          </li>
          <li>
            <Link to="#colours">Colours</Link>
          </li>
          <li>
            <Link to="#grid">Grid</Link>
          </li>
          <li>
            <Link to="#desktop-mocks">Desktop Mocks</Link>
          </li>
          <li>
            <Link to="#mobile-mocks">Mobile Mocks</Link>
          </li>
        </ol>
      </div>

      <header className="CS__masthead">
        <div className={`AppleII__masthead ${isDefault ? '' : 'dark'}`}>
          <div className="title-bar">
            <div className="title">Scrapbook</div>
            <ul>
              <li
                className="icon--close"
                onClick={() => {
                  alert('ERROR 418! - PICNIC');
                }}></li>
              <li
                className="icon--maximize"
                onClick={() => {
                  alert('ERROR 418! - PICNIC');
                }}></li>
            </ul>
          </div>
          <div className="content">
            <img className="masthead__brand-logo" src={BrandLogo} alt="" />
          </div>
          <div className="scroll-bar--bottom"></div>
          <div className="scroll-bar--right">
            <div className="theme-change" onClick={toggleTheme}></div>
          </div>
        </div>
      </header>

      <main className="case-study ">
        <section className="CS__intro" id="intro">
          <article>
            <h2>Task</h2>
            <p>
              Tasked with reviving the current brand to align with how the business has developed over the years. This included new brand identity, imagery with
              the likes of photography, icon and new website which would funnel future software projects but primarily be used to help hire new applicants into
              the business.
            </p>

            <h2>Soultion</h2>
            <p>
              The direction taken in regards to the site design was to go with a modern corporate feel but split the site into two halves. The homepage would be
              the customer and business-facing part of the site using clean design, strong calming colours giving of a sense of security, trust and then
              maximise on whitespace as much as possible. The direction taken in regards to the site design was to go with a modern corporate feel but split the
              site into two halves.
              <br />
              The homepage would be the customer and business-facing part of the site using clean design, strong calming colours giving of a sense of security,
              trust and then maximise on whitespace as much as possible.
            </p>

            <p>
              The other part of the site would be used for recurrent and targeted for developers across the entire code stack. There was a lot of discussions
              and mocks produced and in the end, it was decided that we should go with a dark theme which developers could relate to along with eye-catching
              icons, impactful imagery while still keeping the corporate blue aesthetic.
            </p>
          </article>
        </section>

        <section className="CS__moodboard" id="moodboard">
          <h2 data-aos="fade-up">Moodboard</h2>
          <img src={Moodboard} alt="" className="content-padding" data-aos="fade-up" />
          <p className="content-padding" data-aos="fade-up">
            Sitting down with the business director allowed helped narrow down the direction the business is heading going forward and helped create the
            aesthetic for the designs and photography.
          </p>
          <div className="columns" data-aos="fade-up">
            <div className="column is-5 " data-aos="fade-up">
              <img src={BrandSymbolismLeft} alt="" />
            </div>
            <div className="column is-5 is-offset-2" data-aos="fade-up">
              <img src={BrandSymbolismRight} alt="" />
            </div>
          </div>
        </section>

        <section className="CS__typeface" id="typeface" data-aos="fade-up">
          <h2>Typeface</h2>

          <div className="columns" data-aos="fade-up">
            <div className="column is-two-thirds" data-aos="fade-right">
              <img src={TypefaceResearch} alt="" />
            </div>
            <div className="column" data-aos="fade-left">
              <p>Keeping in line with the aesthetic choice we went with a sans-serif, monospaced font and narrowed it down to a select few.</p>
              <p>
                The finalised list was then used on top of some POC's to help us make the right choice which you can see are circled and also rendered below.
              </p>
              <p>Keeping too 1 font face that uses two typefaces is also great to help reduce load times.</p>
            </div>
          </div>
        </section>

        <section className="CS__typeface-selection" data-aos="fade-up">
          <div className="columns">
            <img src={Typeface_Primary} className="column is-6" alt="" />
            <img src={Typeface_Secondary} className="column is-6" alt="" />
          </div>
        </section>

        <section className="CS__icon-design" id="icon-design" data-aos="fade-up">
          <h2>Icon Design</h2>

          <div className="columns">
            <div className="column" data-aos="fade-right">
              <p>
                We had the typeface but now needed to figure out a brand logo. After producing the moodboars it was to pick up the{' '}
                <a href="https://amzn.to/38RJehI">.5 Uni Kurutoga Pipe Slide</a> and started jotting down idea onto dot paper.
              </p>
              <p>
                Countless meetings and many alterations later we had a rough list which we then rendered digitally. Doing so helped speed up the process when it
                came to choosing the right colours and overall polishing up the select list.
              </p>
              <p>
                we wanted anyone viewing the site may they be a customer or a developer know that the business was a Scottish company which is how we ended up
                with the final designs below.
              </p>
            </div>
            <div className="column is-two-thirds" data-aos="fade-left">
              <img src={LogoDesign} alt="" />
              <img src={LogoDesign2} alt="" />
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <img src={LogoDesign3} alt="" />
            </div>
          </div>
        </section>

        <section className="CS__colour-swatch" id="colours" data-aos="fade-up">
          <h2>Colours</h2>

          <img src={ColourSwatch} alt="" />
        </section>

        <section className="CS__grid" id="grid" data-aos="fade-up">
          <h2>Grid</h2>

          <img src={Grid} alt="" />
        </section>

        <section className="CS__mockups" id="desktop-mocks" data-aos="fade-up">
          <h2>Desktop Mockup</h2>

          <div className="columns">
            <div className="column is-5">
              <img src={Mockup_Light} alt="" />
            </div>
            <div className="column is-5 is-offset-2">
              <img src={Mockup_Dark} alt="" />
            </div>
          </div>
        </section>

        <section className="CS__mobile-mockup" id="mobile-mocks" data-aos="fade-up">
          <h2>Mobile Mockup</h2>

          {/* original in Adobe Animate but having issues importing. Gif will do for now, plus I'm digging the rasterized glow aesthetic happening due to compression */}
          {/* <div className="mobile-mockup__glow"></div> */}
          <img src={MobileMockup} alt="" />
        </section>
      </main>
    </div>
  );
};

export default SerialAffinity;
