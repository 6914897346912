import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ICONS from './icons.js';

import './SvgIcon.scss';

const SvgIcon = ({ className, icon, responsive, ...rest }) => {
  if (!(icon in ICONS)) return null; // Nothing to do here!
  const Icon = ICONS[icon];

  return (
    <Icon
      className={cx(`svg-icon svg-icon--${icon}`, className, {
        'svg-icon--responsive': responsive,
      })}
      {...rest}
    />
  );
};

SvgIcon.defaultProps = {
  className: '',
  responsive: false,
};

SvgIcon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  responsive: PropTypes.bool,
};

export default memo(SvgIcon);
