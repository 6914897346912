import { useState } from 'react';

export const useMove = () => {
  const [posX, setPosX] = useState();
  const [posY, setPosY] = useState();

  const handleMouseMove = (e) => {
    const calcXPos = Math.trunc((window.innerWidth / 2 - e.clientX) / -20);
    const calcYPos = Math.trunc((window.innerHeight / 2 - e.clientY) / 20);

    e.persist();
    calcXPos !== posX && setPosX(calcXPos);
    calcYPos !== posY && setPosY(calcYPos);
  };

  return {
    position: `rotateY(${posX}deg) rotateX(${posY}deg)`,
    handleMouseMove,
  };
};
export default useMove;
