import React from 'react';
import { Link } from 'react-router-dom';
import SocialLinks from './components/SocialLinks/index.js';
import fluxCapacitor from './assets/imgs/Delorean-by-Visualdon.gif';

const bgPath = fluxCapacitor;

const NotFound = () => {
  return (
    <div className="error__container">
      <div className="error__social-media">
        <SocialLinks />
      </div>
      <div className="glitch__container">
        <div className="overlay" style={{ backgroundImage: `url(${bgPath})` }}>
          404
        </div>
        <div className="glitch" error="404">
          404
        </div>
      </div>

      <div className="error-message__container">
        <h2 className="error__message">
          <span className="spacer"></span>start<span className="spacer"></span>over!
        </h2>
        <div className="hiders">
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </div>
      </div>

      <Link className="ET-home-phone" to="/">
        Phone Home
      </Link>
    </div>
  );
};

export default NotFound;
