import React from 'react';

import './style.scss';

const Skip2Content = () => {
  return (
    <a className="skip-to-content-link" href="#main">
      Skip to content
    </a>
  );
};

export default Skip2Content;
