import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import './style.scss';
import SvgIcon from '../library/SvgIcon/SvgIcon.js';
import techStack from '../../api/tech-stack.json';
import shuffleArray from '../../helpers/shuffleArray.js';

const stackExperienceState = (experience, data) => {
  if (experience === 'Tech Stack') {
    return 'Tech Stack';
  } else if (experience === data.experience) {
    return 'active';
  }
  return 'hidden';
};

const handleTitleChange = (experience) => {
  if (experience === 'proficient') {
    return `${experience} in `;
  } else if (experience === 'exposure') {
    return `${experience} to `;
  }
  return 'Tech Stack';
};

const TechStack = ({ className, aos }) => {
  const [experience, setExperience] = useState('Tech Stack');
  const techStackArray = shuffleArray(techStack.stack);

  return (
    <section className={`tech-stack__container${className}`} data-aos={aos} id="tech-stack">
      <h2 className="tech-stack__title">{handleTitleChange(experience)}</h2>
      <ul className="tech-stack__list">
        {techStackArray.map((data) => {
          const fill = data.hex;
          return (
            <li className={`stack-skill ${stackExperienceState(experience, data)}`} key={data.skill}>
              <a href={data.url} title={data.title} target="_blank" rel="noopener noreferrer" aria-label={data.title}>
                <SvgIcon icon={data.skill} style={{ fill }} width="20px" height="22px" />
              </a>
            </li>
          );
        })}
      </ul>
      <div className="tech-stack__buttons">
        <Link
          className="button--proficient"
          to="#tech-stack"
          onClick={() => {
            experience !== 'proficient' ? setExperience('proficient') : setExperience('Tech Stack');
          }}>
          {experience !== 'proficient' ? 'Proficient' : 'All'}
        </Link>

        <Link
          className="button--exposure"
          type="button"
          to="#tech-stack"
          onClick={() => {
            experience !== 'exposure' ? setExperience('exposure') : setExperience('Tech Stack');
          }}>
          {experience !== 'exposure' ? 'Exposure' : 'All'}
        </Link>
      </div>
    </section>
  );
};

TechStack.propTypes = {};

export default TechStack;
