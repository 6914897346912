// ICONS
import { ReactComponent as cross } from '../../../assets/icons/cross.svg';
import { ReactComponent as arrowDown } from '../../../assets/icons/arrow-down.svg';
import { ReactComponent as phone } from '../../../assets/icons/phone.svg';
import { ReactComponent as plus } from '../../../assets/icons/plus.svg';
import { ReactComponent as minus } from '../../../assets/icons/minus.svg';
import { ReactComponent as check } from '../../../assets/icons/check.svg';
import { ReactComponent as questionmark } from '../../../assets/icons/questionmark.svg';

// Brand Logos
import { ReactComponent as adobeillustrator } from '../../../assets/tech-stack/adobeillustrator.svg';
import { ReactComponent as adobeindesign } from '../../../assets/tech-stack/adobeindesign.svg';
import { ReactComponent as adobelightroomclassic } from '../../../assets/tech-stack/adobelightroomclassic.svg';
import { ReactComponent as adobephotoshop } from '../../../assets/tech-stack/adobephotoshop.svg';
import { ReactComponent as adobexd } from '../../../assets/tech-stack/adobexd.svg';
import { ReactComponent as apollographql } from '../../../assets/tech-stack/apollographql.svg';
import { ReactComponent as babel } from '../../../assets/tech-stack/babel.svg';
import { ReactComponent as circleci } from '../../../assets/tech-stack/circleci.svg';
import { ReactComponent as css3 } from '../../../assets/tech-stack/css3.svg';
import { ReactComponent as eslint } from '../../../assets/tech-stack/eslint.svg';
import { ReactComponent as git } from '../../../assets/tech-stack/git.svg';
import { ReactComponent as github } from '../../../assets/tech-stack/github.svg';
import { ReactComponent as googleanalytics } from '../../../assets/tech-stack/googleanalytics.svg';
import { ReactComponent as googletagmanager } from '../../../assets/tech-stack/googletagmanager.svg';
import { ReactComponent as graphql } from '../../../assets/tech-stack/graphql.svg';
import { ReactComponent as gulp } from '../../../assets/tech-stack/gulp.svg';
import { ReactComponent as html5 } from '../../../assets/tech-stack/html5.svg';
import { ReactComponent as invision } from '../../../assets/tech-stack/invision.svg';
import { ReactComponent as javascript } from '../../../assets/tech-stack/javascript.svg';
import { ReactComponent as jira } from '../../../assets/tech-stack/jira.svg';
import { ReactComponent as jquery } from '../../../assets/tech-stack/jquery.svg';
import { ReactComponent as markdown } from '../../../assets/tech-stack/markdown.svg';
import { ReactComponent as mysql } from '../../../assets/tech-stack/mysql.svg';
import { ReactComponent as netlify } from '../../../assets/tech-stack/netlify.svg';
import { ReactComponent as npm } from '../../../assets/tech-stack/npm.svg';
import { ReactComponent as prettier } from '../../../assets/tech-stack/prettier.svg';
import { ReactComponent as react } from '../../../assets/tech-stack/react.svg';
import { ReactComponent as redux } from '../../../assets/tech-stack/redux.svg';
import { ReactComponent as sass } from '../../../assets/tech-stack/sass.svg';
import { ReactComponent as sentry } from '../../../assets/tech-stack/sentry.svg';
import { ReactComponent as serverless } from '../../../assets/tech-stack/serverless.svg';
import { ReactComponent as slack } from '../../../assets/tech-stack/slack.svg';
import { ReactComponent as storybook } from '../../../assets/tech-stack/storybook.svg';
import { ReactComponent as typescript } from '../../../assets/tech-stack/typescript.svg';
import { ReactComponent as vim } from '../../../assets/tech-stack/vim.svg';
import { ReactComponent as webpack } from '../../../assets/tech-stack/webpack.svg';
import { ReactComponent as wordpress } from '../../../assets/tech-stack/wordpress.svg';
import { ReactComponent as yarn } from '../../../assets/tech-stack/yarn.svg';
import { ReactComponent as bulma } from '../../../assets/tech-stack/bulma.svg';
import { ReactComponent as sketch } from '../../../assets/tech-stack/sketch.svg';
import { ReactComponent as gatsby } from '../../../assets/tech-stack/gatsby.svg';
import { ReactComponent as aframe } from '../../../assets/tech-stack/aframe.svg';
import { ReactComponent as salesforce } from '../../../assets/tech-stack/salesforce.svg';
import { ReactComponent as jest } from '../../../assets/tech-stack/jest.svg';
import { ReactComponent as blender } from '../../../assets/tech-stack/blender.svg';
import { ReactComponent as greensock } from '../../../assets/tech-stack/greensock.svg';
import { ReactComponent as php } from '../../../assets/tech-stack/php.svg';

// Social
import { ReactComponent as dribbble } from '../../../assets/social/dribbble.svg';
import { ReactComponent as linkedin } from '../../../assets/social/linkedin.svg';
import { ReactComponent as stackshare } from '../../../assets/social/stackshare.svg';
import { ReactComponent as twitter } from '../../../assets/social/twitter.svg';
import { ReactComponent as codepen } from '../../../assets/social/codepen.svg';
import { ReactComponent as instagram } from '../../../assets/social/instagram.svg';
import { ReactComponent as keybase } from '../../../assets/social/keybase.svg';
import { ReactComponent as googleplay } from '../../../assets/social/googleplay.svg';
import { ReactComponent as pinterest } from '../../../assets/social/pinterest.svg';
import { ReactComponent as spotify } from '../../../assets/social/spotify.svg';
import { ReactComponent as googlepodcasts } from '../../../assets/social/googlepodcasts.svg';
import { ReactComponent as applepodcasts } from '../../../assets/social/applepodcasts.svg';

// Brand Logos
import { ReactComponent as baptiste } from '../../../assets/brand-logos/baptiste.svg';
import { ReactComponent as cigna } from '../../../assets/brand-logos/cigna.svg';
import { ReactComponent as conrad } from '../../../assets/brand-logos/conrad.svg';
import { ReactComponent as doubletree } from '../../../assets/brand-logos/doubletree.svg';
import { ReactComponent as Fdm } from '../../../assets/brand-logos/fdm.svg';
import { ReactComponent as flavourCore } from '../../../assets/brand-logos/flavourCore.svg';
import { ReactComponent as gant } from '../../../assets/brand-logos/gant.svg';
import { ReactComponent as hilton } from '../../../assets/brand-logos/hilton.svg';
import { ReactComponent as hunter } from '../../../assets/brand-logos/hunter.svg';
import { ReactComponent as laQuinta } from '../../../assets/brand-logos/laQuinta.svg';
import { ReactComponent as logo } from '../../../assets/brand-logos/logo.svg';
import { ReactComponent as noyane } from '../../../assets/brand-logos/noyane.svg';
import { ReactComponent as royalBritishLegion } from '../../../assets/brand-logos/royalBritishLegion.svg';
import { ReactComponent as Spie } from '../../../assets/brand-logos/spie.svg';
import { ReactComponent as walldorf } from '../../../assets/brand-logos/walldorf.svg';
import { ReactComponent as coc } from '../../../assets/brand-logos/coc.svg';
import { ReactComponent as aa } from '../../../assets/brand-logos/aa.svg';
import { ReactComponent as NationalTyre } from '../../../assets/brand-logos/NationalTyre.svg';
import { ReactComponent as CampervanCo } from '../../../assets/brand-logos/CampervanCo.svg';
import { ReactComponent as vikingTyre } from '../../../assets/brand-logos/vikingTyre.svg';

export default {
  // Icons
  cross,
  arrowDown,
  phone,
  plus,
  minus,
  check,
  questionmark,

  // Social
  dribbble,
  linkedin,
  stackshare,
  twitter,
  codepen,
  instagram,
  keybase,
  googleplay,
  pinterest,
  spotify,
  googlepodcasts,
  applepodcasts,

  // Tech Stack
  adobeillustrator,
  adobeindesign,
  adobelightroomclassic,
  adobephotoshop,
  adobexd,
  apollographql,
  babel,
  circleci,
  css3,
  eslint,
  git,
  github,
  googleanalytics,
  googletagmanager,
  graphql,
  gulp,
  html5,
  invision,
  javascript,
  jira,
  jquery,
  markdown,
  mysql,
  netlify,
  npm,
  prettier,
  react,
  redux,
  sass,
  sentry,
  serverless,
  slack,
  storybook,
  typescript,
  vim,
  webpack,
  wordpress,
  yarn,
  bulma,
  sketch,
  gatsby,
  aframe,
  salesforce,
  jest,
  blender,
  greensock,
  php,

  // Brand-Logos
  baptiste,
  cigna,
  conrad,
  doubletree,
  Fdm,
  flavourCore,
  gant,
  hilton,
  hunter,
  laQuinta,
  logo,
  noyane,
  royalBritishLegion,
  Spie,
  walldorf,
  coc,
  NationalTyre,
  aa,
  CampervanCo,
  vikingTyre,
};
