import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import { HashLink as Link, NavHashLink } from 'react-router-hash-link';
import { ReactComponent as MobileMenu } from '../../assets/icons/hamburger-menu.svg';
import Headroom from 'react-headroom';
import { ReactComponent as Logo } from '../../assets/imgs/markaustin-dev__logo.svg';
import SocialLinks from '../SocialLinks/index.js';
import ScrollToTop from '../library/ScrollToTop/index.js';
import Konami from 'react-konami-code';
import Glitch from '../library/Glitch';
import './style.scss';

const Navigation = () => {
  const easterEgg = Glitch;

  const [isMobileMenuOpen, setMobileMenuBool] = useState(false);

  const handleMobileMenuClick = () => setMobileMenuBool(!isMobileMenuOpen);

  return (
    <Headroom className={`menu ${isMobileMenuOpen && 'mobile-menu--open'}`} upTolerance={2}>
      <ScrollToTop />

      <nav className="nav-container">
        <Link to="/" aria-label="homepage" className="brand-logo">
          <Logo
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          />
        </Link>
        <div className="menu-items">
          <ul>
            <li className="menu__drop-down">
              <NavHashLink
                activeClassName="active"
                to="/"
                onClick={() => {
                  setMobileMenuBool(false);
                }}>
                Home
              </NavHashLink>
              <ul>
                <li>
                  <NavHashLink
                    activeClassName="active"
                    to="/#work"
                    onClick={() => {
                      setMobileMenuBool(false);
                    }}>
                    <span>Go to </span>Work
                  </NavHashLink>
                </li>
                <li>
                  <NavHashLink
                    activeClassName="active"
                    to="/#tech-stack"
                    onClick={() => {
                      setMobileMenuBool(false);
                    }}>
                    <span>Go to </span>Stack
                  </NavHashLink>
                </li>
                <li>
                  <NavHashLink
                    activeClassName="active"
                    to="/#interests"
                    onClick={() => {
                      setMobileMenuBool(false);
                    }}>
                    <span>Go to </span>Interests
                  </NavHashLink>
                </li>
              </ul>
            </li>

            <li>
              <NavHashLink
                activeClassName="active"
                to="/uses"
                onClick={() => {
                  setMobileMenuBool(false);
                }}>
                <span>Go to </span>Uses
              </NavHashLink>
            </li>

            {/* <li className="menu__drop-down">
              <NavHashLink
                activeClassName="active"
                to="/serial-affinity"
                onClick={() => {
                  setMobileMenuBool(false);
                }}>
                <span>Go to </span>Cases
              </NavHashLink>
              <ul>
                <li>
                  <NavHashLink
                    activeClassName="active"
                    to="/serial-affinity"
                    onClick={() => {
                      setMobileMenuBool(false);
                    }}>
                    <span>Go to </span>Serial Affinity
                  </NavHashLink>
                </li>
              </ul>
            </li> */}

            <li>
              <a href="mailto:me@markaustin.dev" subject="markaustin.dev enquiry! - *add your message here*" rel="noopener noreferrer" target="_blank">
                Contact
              </a>
            </li>
          </ul>

          <div className="header__networking">
            <SocialLinks />
          </div>
        </div>
        <MobileMenu tabIndex="0" aria-label="Open navigation menu" className="hamburger-menu" onClick={handleMobileMenuClick} />
      </nav>
      <Konami action={easterEgg}></Konami>
    </Headroom>
  );
};

export default Navigation;
