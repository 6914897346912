import React from 'react';
import SvgIcon from '../../components/library/SvgIcon/SvgIcon.js';
import ProgressBar from '../../components/library/ProgressBar/index.js';

import VSCode from '../../assets/imgs/vscode.png';
import G910 from '../../assets/imgs/g910.png';
import G502 from '../../assets/imgs/g502.png';

import './style.scss';

const Uses = () => {
  return (
    <div className="uses__container">
      <ProgressBar />

      <header>
        <h1 className="align-c page-masthead ">Uses</h1>
      </header>

      <main>
        <section className="uses__harware">
          <h2 className="title">Hardware</h2>

          <p className="heading__intro">
            <q>
              What I tend to use on a daily basis for work, personal projects or messing around whenever I get down time. The excess of devices is mainly for
              testing purposes if I am unable to replicate a problem within XCode or Android Virtual Device (AVD).
            </q>
          </p>

          <div className="columns is-multiline">
            <article className="column is-half ">
              <h3>Machines</h3>

              <ul>
                <li>
                  <a
                    href="https://www.ebay.co.uk/sch/i.html?_from=R40&_nkw=macbook+pro+&_sacat=0&LH_TitleDesc=0&_blrs=spell_check&Release%2520Year=2019&RAM%2520Size=16%2520GB&Hard%2520Drive%2520Capacity=500%252D749%2520GB&_dcat=111422&rt=nc&LH_Auction=1"
                    target="_blank"
                    rel="noopener noreferrer">
                    MacBook Pro 13" 2019 Touch Bar 2.4GHz i5 16GB 512GB
                  </a>
                </li>
                <li>MSI - CX61 2QF </li>
                <li>
                  <a href="https://amzn.to/3lDWmdo" target="_blank" rel="noopener noreferrer">
                    Raspberry Pi 3 Model B+
                  </a>
                </li>
              </ul>
            </article>

            <article className="column is-half">
              <h3>Phones</h3>
              <ul>
                <li>
                  <a href="https://amzn.to/2IoXXFI" target="_blank" rel="noopener noreferrer">
                    Samsung S20+ 5G
                  </a>
                </li>
                <li>
                  <a href="https://amzn.to/2SOAyzn" target="_blank" rel="noopener noreferrer">
                    OnePlus 2 & 6
                  </a>
                </li>
                <li>
                  <a href="https://amzn.to/2SOSIkv" target="_blank" rel="noopener noreferrer">
                    Pixel 2
                  </a>
                </li>
              </ul>
            </article>

            <article className="column is-half ">
              <h3>External Devices</h3>
              <ul>
                <li>
                  <a href="https://amzn.to/2FoNZTv" target="_blank" rel="noopener noreferrer">
                    Icy Box IB-3620U3 External 2 Bay JBOD System
                  </a>
                </li>
                <li>
                  <a href="https://amzn.to/3iPlyf3" target="_blank" rel="noopener noreferrer">
                    2 x Western Digital 2TB HDD Blue
                  </a>
                </li>
                <li>
                  <a href="https://amzn.to/2SNaMLJ" target="_blank" rel="noopener noreferrer">
                    Ultra High Capacity 22400mAh Power Bank (5.5A)
                  </a>
                </li>
                <li>
                  <a href="https://amzn.to/33TzOzb" target="_blank" rel="noopener noreferrer">
                    2 X HooToo HT-UH010 7-Port USB 3.0 Hub
                  </a>
                </li>
              </ul>
            </article>

            <article className="column is-half">
              <h3>Accessories</h3>
              <ul>
                <li>
                  <a href="https://amzn.to/3jV85E2" target="_blank" rel="noopener noreferrer">
                    StarTech Dual DisplayPort to Thunderbolt 3 Adapter 4K 60Hz
                  </a>
                </li>
                <li>
                  <a href="https://amzn.to/3dp7tnq" target="_blank" rel="noopener noreferrer">
                    Kanex USB C to Gigabit Ethernet Adapter
                  </a>
                </li>
                <li>
                  <a href="https://amzn.to/3dkH76l" target="_blank" rel="noopener noreferrer">
                    Apple USB-C Digital AV Multiport Adapter
                  </a>
                </li>
                <li>
                  <a href="https://amzn.to/3iXcZim" target="_blank" rel="noopener noreferrer">
                    CAT6 Ethernet Gigabit Patch Cable (20m)
                  </a>
                </li>
                <li>
                  <a href="https://amzn.to/2SRiuoq" target="_blank" rel="noopener noreferrer">
                    ASUS RT-N66U
                  </a>
                  &nbsp; (
                  <a href="https://www.asuswrt-merlin.net/" target="_blank" rel="noopener noreferrer">
                    asuswrt-merlin
                  </a>
                  )
                </li>
              </ul>
            </article>

            <article className="column is-half ">
              <h3>Monitors</h3>
              <ul>
                <li>
                  <a href="https://amzn.to/2IfxmdZ" target="_blank" rel="noopener noreferrer">
                    2 x PHILIPS 21.5\" 226E9QHAB
                  </a>
                </li>
              </ul>
            </article>

            <article className="column is-half">
              <h3>Keyboard</h3>
              <ul>
                <li>
                  <a href="https://amzn.to/33Uy3Sg" target="_blank" rel="noopener noreferrer">
                    Logitech G910 Orion Spectrum Keyboard
                  </a>
                </li>
                <li>
                  <a href="https://amzn.to/2GVF5gZ" target="_blank" rel="noopener noreferrer">
                    AKAI LPK25 Portable MIDI Keyboard
                  </a>
                </li>
              </ul>
            </article>

            <article className="column is-half">
              <h3>Mouse</h3>
              <ul>
                <li>
                  <a href="https://amzn.to/2SPeepf" target="_blank" rel="noopener noreferrer">
                    Logitech G502 HERO
                  </a>
                </li>
                <li>
                  <a href="https://amzn.to/2GZwoC2" target="_blank" rel="noopener noreferrer">
                    Logitech G640 Cloth Gaming Mouse Pad
                  </a>
                </li>
              </ul>
            </article>

            <article className="column is-half">
              <h3>Music</h3>
              <ul>
                <li>
                  <a href="https://amzn.to/3lHwE7W" target="_blank" rel="noopener noreferrer">
                    Boltune BT-BH010S Noise cancelling Headphones
                  </a>
                </li>
              </ul>
            </article>
          </div>
        </section>

        <section className="uses__enviroment">
          <h2 className="title">Dev - Enviroment</h2>

          <p className="heading__intro">
            <q>
              Over the past decade the IDE I use has changed from humble beginings such as <strike>DW & Frontpage onto </strike> Sublime then on to ATOM and
              finally VSCode. I was reluctant at first but its I doubt I will move away from it anytime shortly, specially with the{' '}
              <a
                href="https://gist.github.com/mark-vb-austin/b9fec803e5a431d8ae1a96af3d3b3179#file-extensions-json"
                className="link--bright"
                target="_blank"
                title="open link to extension gist list on Github"
                rel="noopener noreferrer">
                vast amount of Extentions I use.
              </a>
            </q>
          </p>

          <div className="columns is-multiline">
            <article className="column is-half ">
              <h3>VSCode</h3>
              <ul>
                <li>
                  Theme:{' '}
                  <a href="https://marketplace.visualstudio.com/items?itemName=monokai.theme-monokai-pro-vscode" target="_blank" rel="noopener noreferrer">
                    Monokai Pro (Spectrum)
                  </a>
                </li>
                <li>
                  Font:{' '}
                  <a href="https://github.com/tonsky/FiraCode" target="_blank" rel="noopener noreferrer">
                    Fira Code
                  </a>
                </li>
                <li>
                  <a href="https://gist.github.com/mark-vb-austin/30f6c1eeafe59a34bb49f9b78fceb16b" target="_blank" rel="noopener noreferrer">
                    Exentions & Settings
                  </a>
                </li>
                <li>
                  <strike>Sequel Pro</strike> <a href="https://github.com/Sequel-Ace/Sequel-Ace">Sequal Ace</a>
                </li>
              </ul>
            </article>

            <article className="column is-half ">
              <h3>Haxor</h3>
              <ul>
                <li>
                  Terminal: <a href="https://www.iterm2.com/">iTerm 2</a> / <a href="https://conemu.github.io/">ConEmu</a>
                </li>
                <li>
                  Shell:{' '}
                  <a href="https://github.com/ohmyzsh/ohmyzsh" target="_blank" rel="noopener noreferrer">
                    Oh-my-Zsh
                  </a>
                </li>
                <li>
                  Colours: Monokai Remaster
                  <br />
                  Theme: Powerlevel9k
                </li>
              </ul>
            </article>
          </div>
        </section>

        <section className="uses__photograpghy">
          <h2 className="title">Photograpghy</h2>

          <p className="heading__intro">
            <q>
              Has always been a pashion of mine, but it does become a challenge having to{' '}
              <span title="Scots slang: the act of carrying a heavy burden">humph</span> around a clunky DSLR around all the time in Scottish weather let alone
              when having to chase after two young children. Which is by I tend to pickup the S20+ more often that not.
            </q>
          </p>

          <div className="columns is-multiline">
            <article className="column is-half ">
              <h3>DSLR</h3>
              <ul>
                <li>
                  <a href="https://amzn.to/3iV7fG2" target="_blank" rel="noopener noreferrer">
                    Nikon D5600
                  </a>
                </li>
                <li>
                  <a href="https://amzn.to/2SLQaUm" target="_blank" rel="noopener noreferrer">
                    35mm Prime AF-S 1.8
                  </a>
                </li>
                <li>
                  <a href="https://amzn.to/3dm3RCQ" target="_blank" rel="noopener noreferrer">
                    18-55 AF-P VR Lens
                  </a>
                </li>
                <li>
                  <a href="https://amzn.to/3dkKNoF" target="_blank" rel="noopener noreferrer">
                    55-200 AF-S 4-5.6 ED
                  </a>
                </li>
                <li>
                  <a href="https://amzn.to/3doPa1H" target="_blank" rel="noopener noreferrer">
                    SanDisk Extreme PRO 64GB
                  </a>
                </li>
                <li>
                  <a href="https://amzn.to/3iVlWss" target="_blank" rel="noopener noreferrer">
                    Beeway Memory Card Holder
                  </a>
                </li>
                <li>
                  <a href="https://amzn.to/313eSE2" target="_blank" rel="noopener noreferrer">
                    Multiple Hip Holsters
                  </a>
                </li>
                <li>
                  <a href="https://amzn.to/2FmOpK2" target="_blank" rel="noopener noreferrer">
                    Manfrotto PIXI EVO
                  </a>
                </li>
                <li>
                  <a href="https://amzn.to/3lJ5yNs" target="_blank" rel="noopener noreferrer">
                    Multiple Batteries
                  </a>
                </li>
                <li>Misc bits and bot</li>
              </ul>
            </article>
          </div>
        </section>

        <section className="uses__media">
          <h2 className="title">Media</h2>

          <p className="heading__intro">
            <q>
              When im not using the left side of the brain for development and switch to something creative, ill stop the music and start listening to podcasts.
              The list below are short few that I enjoy listening to.
            </q>
          </p>

          <div className="columns is-multiline">
            <article className="column is-half podcasts">
              <h3>podcasts</h3>
              <ul>
                <li>
                  Darknet Diaries
                  <a href="https://open.spotify.com/show/4XPl3uEEL9hvqMkoZrzbx5?si=VipcApTETpaaxPr2hmwkYA" target="_blank" rel="noopener noreferrer">
                    <SvgIcon icon="spotify" width="15px" height="15px" title="Listen on Spotify (external link)" />
                  </a>
                  <a href="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5tZWdhcGhvbmUuZm0vZGFya25ldGRpYXJpZXM" target="_blank" rel="noopener noreferrer">
                    <SvgIcon icon="googlepodcasts" width="15px" height="15px" title="Listen on Google Podcasts (external link)" />
                  </a>
                  <a href="https://podcasts.apple.com/gb/podcast/darknet-diaries/id1296350485" target="_blank" rel="noopener noreferrer">
                    <SvgIcon icon="applepodcasts" width="15px" height="15px" title="Listen on Apple Podcasts (external link)" />
                  </a>
                </li>
                <li>
                  Front EndHappy Hour
                  <a href="https://open.spotify.com/show/0Giuw6eNbTzP9CDZODDrA2?si=y3lKHKrpTJ-I2l2vRjAn2A" target="_blank" rel="noopener noreferrer">
                    <SvgIcon icon="spotify" width="15px" height="15px" title="Listen on Spotify (external link)" />
                  </a>
                  <a href="https://podcasts.google.com/search/Front%20EndHappy%20Hour" target="_blank" rel="noopener noreferrer">
                    <SvgIcon icon="googlepodcasts" width="15px" height="15px" title="Listen on Google Podcasts (external link)" />
                  </a>
                  <a href="https://podcasts.apple.com/gb/podcast/front-end-happy-hour/id1089047924" target="_blank" rel="noopener noreferrer">
                    <SvgIcon icon="applepodcasts" width="15px" height="15px" title="Listen on Apple Podcasts (external link)" />
                  </a>
                </li>
                <li>
                  Past Gas
                  <a href="https://open.spotify.com/show/0KhLZruE5HhtmoLAuP0Lu3?si=g_SYXe5OTEWnTxE-ayhFsA" target="_blank" rel="noopener noreferrer">
                    <SvgIcon icon="spotify" width="15px" height="15px" title="Listen on Spotify (external link)" />
                  </a>
                  <a
                    href="https://podcasts.google.com/feed/aHR0cDovL2ZlZWRwcm94eS5nb29nbGUuY29tL1Bhc3RHYXM?sa=X&ved=0CAIQ4aUDahcKEwiwptCfnLHsAhUAAAAAHQAAAAAQFw"
                    target="_blank"
                    rel="noopener noreferrer">
                    <SvgIcon icon="googlepodcasts" width="15px" height="15px" title="Listen on Google Podcasts (external link)" />
                  </a>
                  <a href="https://podcasts.apple.com/gb/podcast/past-gas-by-donut-media/id1479468209" target="_blank" rel="noopener noreferrer">
                    <SvgIcon icon="applepodcasts" width="15px" height="15px" title="Listen on Apple Podcasts (external link)" />
                  </a>
                </li>
                <li>
                  Last podcast on the left
                  <a href="https://open.spotify.com/show/3yZg2MCkf31pPXiG4nznrg?si=x7wmZOLzSXSYia7e0baUnA" target="_blank" rel="noopener noreferrer">
                    <SvgIcon icon="spotify" width="15px" height="15px" title="Listen on Spotify (external link)" />
                  </a>
                </li>
                <li>
                  Syntax FM
                  <a href="https://open.spotify.com/show/4kYCRYJ3yK5DQbP5tbfZby?si=GE3e8fmHQvagvUD1VTqZ2A" target="_blank" rel="noopener noreferrer">
                    <SvgIcon icon="spotify" width="15px" height="15px" title="Listen on Spotify (external link)" />
                  </a>
                  <a
                    href="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkLnN5bnRheC5mbS9yc3M?sa=X&ved=0CAIQ4aUDahcKEwiwptCfnLHsAhUAAAAAHQAAAAAQIw"
                    target="_blank"
                    rel="noopener noreferrer">
                    <SvgIcon icon="googlepodcasts" width="15px" height="15px" title="Listen on Google Podcasts (external link)" />
                  </a>
                  <a href="https://podcasts.apple.com/gb/podcast/syntax-tasty-web-development-treats/id1253186678" target="_blank" rel="noopener noreferrer">
                    <SvgIcon icon="applepodcasts" width="15px" height="15px" title="Listen on Apple Podcasts (external link)" />
                  </a>
                </li>
                <li>
                  We are Netflix
                  <a href="https://open.spotify.com/show/3v8L3oLZrb5fQZHW7cvGMm?si=CQNAv4PyS22wUa1VhtCjtg" target="_blank" rel="noopener noreferrer">
                    <SvgIcon icon="spotify" width="15px" height="15px" title="Listen on Spotify (external link)" />
                  </a>
                  <a href="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5zaW1wbGVjYXN0LmNvbS9RQU41bF96NQ" target="_blank" rel="noopener noreferrer">
                    <SvgIcon icon="googlepodcasts" width="15px" height="15px" title="Listen on Google Podcasts (external link)" />
                  </a>
                  <a href="https://podcasts.apple.com/gb/podcast/wearenetflix/id1360511576" target="_blank" rel="noopener noreferrer">
                    <SvgIcon icon="applepodcasts" width="15px" height="15px" title="Listen on Apple Podcasts (external link)" />
                  </a>
                </li>
                <li>
                  Hacked
                  <a href="https://open.spotify.com/show/21zZfOy7VCSIIWlJ64DElv?si=9Hd6iC2LQ1yExmwQZwbo3w" target="_blank" rel="noopener noreferrer">
                    <SvgIcon icon="spotify" width="15px" height="15px" title="Listen on Spotify (external link)" />
                  </a>
                  <a
                    href="https://podcasts.google.com/feed/aHR0cHM6Ly93d3cuaGFja2VkcG9kY2FzdC5jb20vZmVlZC9wb2RjYXN0Lw?sa=X&ved=0CAIQ4aUDahcKEwiwptCfnLHsAhUAAAAAHQAAAAAQPw"
                    target="_blank"
                    rel="noopener noreferrer">
                    <SvgIcon icon="googlepodcasts" width="15px" height="15px" title="Listen on Google Podcasts (external link)" />
                  </a>
                  <a href="https://podcasts.apple.com/gb/podcast/hacked/id1049420219" target="_blank" rel="noopener noreferrer">
                    <SvgIcon icon="applepodcasts" width="15px" height="15px" title="Listen on Apple Podcasts (external link)" />
                  </a>
                </li>
                <li>
                  Malicious Life
                  <a href="https://open.spotify.com/show/1KHIsaZ9mX0NbzPrfId00q?si=P73UH3YoRnGAUWqkqCtFjQ" target="_blank" rel="noopener noreferrer">
                    <SvgIcon icon="spotify" width="15px" height="15px" title="Listen on Spotify (external link)" />
                  </a>
                  <a href="https://podcasts.google.com/feed/aHR0cHM6Ly9tYWxpY2lvdXMubGlmZS9mZWVkL3BvZGNhc3Qv" target="_blank" rel="noopener noreferrer">
                    <SvgIcon icon="googlepodcasts" width="15px" height="15px" title="Listen on Google Podcasts (external link)" />
                  </a>
                  <a href="https://podcasts.apple.com/gb/podcast/malicious-life/id1252417787" target="_blank" rel="noopener noreferrer">
                    <SvgIcon icon="applepodcasts" width="15px" height="15px" title="Listen on Apple Podcasts (external link)" />
                  </a>
                </li>
              </ul>
            </article>

            <article className="column is-half ">
              <h3>Misc</h3>
              <ul>
                <li>
                  <a href="https://open.spotify.com/user/mark.vb.austin" target="_blank" rel="noopener noreferrer">
                    Spotify
                  </a>
                </li>
                <li>
                  <a href="https://www.plex.tv/">Plex</a> & <a href="https://kodi.tv/">Kodi</a> for personal Media
                </li>
              </ul>
            </article>
          </div>
        </section>

        <section className="config__cards">
          <h2 className="title">Config / Settings</h2>

          <div className="columns ">
            <article className="column is-one-third config-card--keyboard ">
              <img src={G910} alt="" />
              <h3>G910</h3>
              Theme(s):
              <br />
              <br />
              <ul>
                <li>Derp Rainbow by M2K</li>
                <li>Pinkblue Smoooth by </li>
              </ul>
            </article>

            <article className="column is-one-third config-card--vscode">
              <img src={VSCode} alt="" />
              <h3>VSCode</h3>
              <ul>
                <li>
                  Theme: <i>Monokai Pro (Filter Spectrum)</i>
                </li>
                <li>
                  Font: <i>FiraCode 13px 200w (Ligatures)</i>
                </li>
                <li>
                  Icons: <i>VSCode Icons</i>
                </li>
                <li>
                  Extensions: &nbsp;
                  <a
                    href="https://gist.github.com/mark-vb-austin/b9fec803e5a431d8ae1a96af3d3b3179#file-extensions-json"
                    className="link--bright"
                    target="_blank"
                    title="open link to extension gist list on Github"
                    rel="noopener noreferrer">
                    Fifty-Three
                  </a>
                </li>
                prettier config eslint config
              </ul>
            </article>

            <article className="column is-one-third config-card--mouse">
              <img src={G502} alt="" />
              <h3>G502</h3>
              Lighting:
              <ul>
                <li className="primary">
                  Primary - Fixed <span>(#FF0579)</span> <i></i>
                </li>
                <li className="secondary">
                  Logo - Fixed <span>(#5AE1FF)</span> <i></i>
                </li>
              </ul>
              <br />
              Sensitivity (DPI)
              <ol id="mouse-dpi">
                <li className="shift" title="precision mode">
                  700
                </li>
                <li>1600</li>
                <li className="default" title="default">
                  2400
                </li>
                <li>3000</li>
                <li>4000</li>
              </ol>
            </article>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Uses;
