//Fisher-Yates Algorithm
export const shuffleArray = (array) => {
  let loop = array.length,
    temp,
    increment;

  while (loop) {
    increment = Math.floor(Math.random() * loop--);
    temp = array[loop];
    array[loop] = array[increment];
    array[increment] = temp;
  }
  return array;
};

export default shuffleArray;
