import React from 'react';
import SvgIcon from '../library/SvgIcon/SvgIcon.js';

import './style.scss';

const SocialLinks = () => {
  return (
    <ul className="network__list">
      <li className="network__item">
        <a href="https://github.com/mark-vb-austin">
          <SvgIcon icon="github" width="30px" height="30px" />
        </a>
      </li>
      <li className="network__item">
        <a href="https://www.linkedin.com/in/austinmark/">
          <SvgIcon icon="linkedin" width="30px" height="30px" />
        </a>
      </li>
      <li className="network__item">
        <a href="https://codepen.io/markvbaustin">
          <SvgIcon icon="codepen" width="30px" height="30px" />
        </a>
      </li>
      <li className="network__item">
        <a href="https://www.instagram.com/markvbaustin/">
          <SvgIcon icon="instagram" width="30px" height="30px" />
        </a>
      </li>
      <li className="network__item">
        <a href="https://twitter.com/Mark_vb_austin">
          <SvgIcon icon="twitter" width="30px" height="30px" />
        </a>
      </li>
      <li className="network__item">
        <a href="https://games.app.goo.gl/hp1edHmggBZFX7kY6">
          <SvgIcon icon="googleplay" width="30px" height="30px" />
        </a>
      </li>
      <li className="network__item">
        <a href="https://www.pinterest.co.uk/markvbaustin/synthwave/">
          <SvgIcon icon="pinterest" width="30px" height="30px" />
        </a>
      </li>
      <li className="network__item">
        <a href="https://open.spotify.com/user/mark.vb.austin?si=Jqo7ssSjTyWxc2KlQ18VXQ">
          <SvgIcon icon="spotify" width="30px" height="30px" />
        </a>
      </li>
    </ul>
  );
};

export default SocialLinks;
