import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Logo } from '../../assets/imgs/markaustin-dev__logo.svg';
import cx from 'classnames';

import useBreakpoints from '../../helpers/hooks/useBreakpoints.js';

import { useMove } from '../../helpers/hooks/useMove';
import './style.scss';

const Hero = () => {
  const { position, handleMouseMove } = useMove();

  const breakpoints = useBreakpoints();
  const isMobile = !breakpoints['mobile-l'];

  return (
    <div
      className={cx(`hero__container`, {
        active: isMobile,
      })}
      onMouseMove={handleMouseMove}>
      <div className="hero-logo__wrap" style={{ transform: position }}>
        <Logo className="hero__logo hero__logo--foreground" />
        <Logo className="hero__logo hero__logo--background" />
      </div>
    </div>
  );
};

Hero.propTypes = {
  mobile: PropTypes.bool,
};

export default Hero;
