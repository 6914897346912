import React, { useEffect, useState } from 'react';

import './style.scss';

const ProgressBar = () => {
  const [scrolled, setScrollPos] = useState(0);

  const scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;
    const winHeightPx = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scrolled = `${(scrollPx / winHeightPx) * 100}%`;

    setScrollPos(scrolled);
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollProgress);
    return () => {
      return window.removeEventListener('scroll', scrollProgress);
    };
  }, []);

  return (
    <div className="progress-container">
      <div className="progress-bar" style={{ width: scrolled }} />
    </div>
  );
};

export default ProgressBar;
