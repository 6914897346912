import React from 'react';
import interests from '../../api/interests.json';
import shuffleArray from '../../helpers/shuffleArray.js';

import './style.scss';
const interestsArray = shuffleArray(interests.hobbies);

const Interests = ({ aos }) => {
  return (
    <section className="interests__container" data-aos={aos} id="interests">
      <h2>interests</h2>

      <ul className="interests__list">
        {interestsArray.map((data, index) => {
          return (
            <li key={index} className="interests__list-item">
              {data}
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default Interests;
