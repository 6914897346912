import Start from '../../../assets/sounds/start.mp3';

function glitch() {
  setInterval(
    () =>
      Array.from(document.querySelectorAll('body *'))
        .reduce(
          (o, v) => o.concat(Array.from(v.childNodes).filter((v) => v.nodeType === 3 && v.parentElement.tagName !== 'SCRIPT' && v.data.trim().length > 3)),
          []
        )
        .forEach(
          (v) =>
            (v.data = v.data.replace(/(\b)(\w+?)(\b)/g, (text) =>
              text.length > 2
                ? text[0] +
                  text
                    .slice(1, -1)
                    .split('')
                    .sort(() => 0.7 - Math.random())
                    .join('') +
                  text[text.length - 1]
                : text
            ))
        ),
    100
  );

  const audioStart = new Audio(Start);
  // const audioBeginning = new Audio(Beginning);
  audioStart.play();

  // audioStart.onended = function () {};
  // let images = document.querySelectorAll('img');
  // for (let i = 0; i < images.length; i++) {
  //   const image = images[i];

  //   image.parentNode.classList.add('cheese');
  //   image.classList.add('cheese');
  //   image.src = "cheese"
  // }
}
export default glitch;
