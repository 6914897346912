import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import Navigation from './components/Navigation';
import Footer from './components/Footer';

class Layout extends Component {
  render() {
    return (
      <Fragment>
        {this.props.location.pathname !== '/404' && <Navigation />}
        {this.props.children}
        {this.props.location.pathname !== '/404' && <Footer />}
      </Fragment>
    );
  }
}

export default withRouter(Layout);
